//=require scripts/helpers.js
const APP = {
	name: 'iBrush HTML Starter'
};

$(() => {
    svg4everybody();
    initPopup();
    initShowMore();
    initPortfolioPopup();

    // floating labels

    $(document).on('focus blur', '.js-input-container input, .js-input-container textarea', function(e) {
        $(this).parents('.js-input-container').toggleClass('is-focused', (e.type === 'focusin' || this.value.length > 0));
    }).trigger('blur');

    $('.js-input-container input, .js-input-container textarea').each(function() {
        if ($(this).val()) {
            $(this).parents('.js-input-container').addClass('is-focused');
        }
    });
    
    $('._mask-phone').inputmask({
        mask: '+7 (999) 999-99-99',
        showMaskOnHover: false
    });


    // file-input
    $('.form__input_file').change(function() {
        var $form = $(this).closest('.js-form');
        var fileList = this.files;
        $form.find('.form__file-box').html(fileList[0].name);
    });
    
    const dropAreaArr = document.querySelectorAll('.js-drop-form');

    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
        dropAreaArr.forEach(dropArea => {
            dropArea.addEventListener(eventName, preventDefaults, false)
        })
    });

    function preventDefaults (e) {
        e.preventDefault()
        e.stopPropagation()
    }
    ['dragenter', 'dragover'].forEach(eventName => {
        dropAreaArr.forEach(dropArea => dropArea.addEventListener(eventName, highlight, false));
    });
    ['dragleave', 'drop'].forEach(eventName => {
        dropAreaArr.forEach(dropArea => dropArea.addEventListener(eventName, unhighlight, false));
    })
    function highlight(e) {
        e.target.classList.add('_highlight');
    }
    function unhighlight(e) {
        e.target.classList.remove('_highlight')
    }
    dropAreaArr.forEach(dropArea => dropArea.addEventListener('drop', handleDrop, false));

    function handleDrop(e) {
        let dt = e.dataTransfer
        let files = dt.files
        handleFiles(files)
    }
    let resumeFile = null;
    function handleFiles(files) {
        let file = [...files][0];
        if(file.type !== 'application/pdf') {
            alert('not pdf');
            return
        }
        resumeFile = file;

        $('.form__file-box').html(file.name);
    }


    // form
    $('.form__form').append('<input class="__form_check" name="check" type="hidden" value="" />');
    $("input[name='page']").val(document.location);
    $(".form_popup input[name='form-name']").val('modal');

    $('.auto-text-area').on('keyup', function() {
        $(this).css('height', 'auto');
        $(this).height(this.scrollHeight);
    });
    
    $('.form__form').append('<input id="check" name="check" type="hidden" value="2" />');
    
    
    $('.header__tg, .hr__tg').on('click', function () {
        ym(80061865, 'reachGoal', 'TG_CLICK');
        return true;
    });
    
    $('.js-form').on('submit', function(e) {
        e.preventDefault();

        var $form = $(this);
        // var is_vac = true;
        var is_vac = $form.find('input#vac_id').length;
        var btn = $form.find('.form__button').attr('disabled', 'true');
            btn.data('text', btn.text()).text('Отправляем...');
        var utm = new URLSearchParams(window.location.search);
        var fd = new FormData($form[0]);

        // fd.append('uploadfile', $form.find('input[type=file]')[0].files[0]); 

        $.ajax({
            data: fd,
            url: '/form/form.php',
            type: 'POST',
            contentType: false,
            processData: false,
            success: function(response) {
                $form[0].reset();
                resumeFile = null;
                $form.find('.form__button').removeAttr('disabled').text(btn.data('text'));

                $.magnificPopup.open({
                    items: {
                        src: '<div class="form_popup form_message"><div class="form"><div class="form_title">Спасибо!</div><div class="form_message">Мы рассмотрим ваше резюме</div></div></div>',
                        type: 'inline'
                    }
                });
                
                ym(80061865, 'reachGoal', 'HR_REQUEST');

            },
            error: function() {

                $form.find('.form__button').removeAttr('disabled').text(btn.data('text'));
                $.magnificPopup.open({
                    items: {
                        src: '<div class="form_popup form_message"><div class="form"><div class="form_title">Что-то пошло не так!</div><div class="form_message">Ваша заявка не отправлена</div></div></div>',
                        type: 'inline'
                    }
                });
            }
        });
    });
});


function initPopup() {
    $('.js-open-popup').magnificPopup({
        type: 'inline',
        midClick: true,
        removalDelay: 100,
        closeOnBgClick: true,
        mainClass: 'mfp-fade',
        fixedContentPos: true,
    });
}

function initShowMore() {
    const $container = $('.js-hide-container');
    if(!$container.length) return

    $('body').on('click', '.js-more-btn', function(e) {
        e.preventDefault();

        const $this = $(this).toggleClass('_expanded');
        let text;

        $this.closest('.js-hide-container').toggleClass('_expanded');

        if($this.hasClass('_expanded')) {
            text = $this.data('expanded');
        } else {
            text = $this.data('default');
        }

        $this.find('.js-text').text(text);
    });
}

function initPortfolioPopup() {
    let portfolioPopups = $('.js-open-portfolio-popup');

    if (portfolioPopups.length) {
        portfolioPopups.each(function() {
            const $this = $(this);
            const caseItem = $this.data('work');
            const ajaxUrl = $this.data('url');
            const caseURL = new URL(document.location.href);
            const getParamName = 'work';
            $this.click(function(e) {
                e.preventDefault();
                const state = { getParamName: caseItem };
                caseURL.searchParams.set(getParamName, caseItem);
                history.pushState(state, document.title, caseURL.search);

                openPopup();
            });

            function openPopup() {
                $.magnificPopup.open({
                    items: {
                        src: ajaxUrl,
                        type: 'ajax'
                    },
                    showCloseBtn: false,
                    closeOnBgClick: true,
                    alignTop: true,
                    fixedContentPos: true,
                    overflowY: 'scroll',
                    preloader: false,
                    callbacks: {
                        ajaxContentAdded: function() {
                            // setTimeout(function() {
                            //     initCaseContent(caseItem);
                            // }, 300);
                            $('.js-close-portfolio-popup').click(function() {
                                $this.magnificPopup('close');
                                caseURL.searchParams.delete(getParamName);
                                history.pushState({}, document.title, '?');
                            })
                        },
                        close() {
                            history.back();
                        }
                    }
                })
            }

            if (caseURL.searchParams.get(getParamName) === caseItem) {
                openPopup();
            }
        })
        let portfolioPopupsContent = $('.js-portfolio-popup')
        portfolioPopupsContent.each(function() {
            $(this).magnificPopup({
                type: 'ajax',
            })
        })
    }
}


